import React from "react"
import { Link } from "gatsby"

import {
  Layout,
  Container,
  SEO,
  Sheet,
  Subscription,
  ThinContainer,
} from "../components/common"

import "./about.css"

export default ({
  pageContext: { totalItems, stats, defaultGradient, footerGradient },
}) => {
  return (
    <Layout
      title="About"
      totalItems={totalItems}
      stats={stats}
      defaultGradient={defaultGradient}
      footerGradient={footerGradient}
    >
      <Container>
        <SEO title="About" />
        <Sheet>
          <ThinContainer>
            <div className="about-text">
              <p>
                Hi, This is Shahadat Rahman, a Bangladeshi product designer,
                graphic designer, speaker & passive happiness earner.
              </p>
              <p>
                Codioful is one of my side project that I made for both
                designers and developers to use ultra lightweight, colorful,
                responsive backgrounds for their personal and commercial
                projects. It is <Link to="/license">free to use</Link>, but
                requires a credit link back to the homepage or the specific page
                (i.e. from the page/URL you'll download an image).
              </p>
              <p>
                All of these gradients are available as CSS codes, SVG and JPG
                images. If you are a designer, you can use SVG or JPG image in
                your projects. Therefore, a developer can use all CSS/SVG/JPG
                (even SVG codes) version in a website or app.
              </p>
              <p>
                Some SVG file or CSS code render differently in different
                browsers and OS. In my opinion, this is beautiful. Why a webpage
                or app interface should look the same in different device? And
                otherwise, you have the option to use JPG image for accuracy.
              </p>
              <p>
                Me and Zonayed teamed up to make all this happen. Praise to
                Allah that we finished it. Feel free to knock any of us to send
                bugs, ideas, suggestions or just connect with us.
              </p>
            </div>
          </ThinContainer>
          <div className="about-portfolio">
            <ul>
              <li>
                <h2>Shahadat Rahman</h2>
                <p>Designed All The Gradients, Website Interface</p>
                <ul className="social">
                  <li>
                    <a
                      target="__blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/HiShahadat"
                    >
                      <span className="icon-twitter"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      target="__blank"
                      rel="noopener noreferrer"
                      href="https://www.instagram.com/hishahadat/"
                    >
                      <span className="icon-instagram"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      target="__blank"
                      rel="noopener noreferrer"
                      href="https://www.linkedin.com/in/hishahadat/"
                    >
                      <span className="icon-linked-in"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      target="__blank"
                      rel="noopener noreferrer"
                      href="https://dribbble.com/hishahadat"
                    >
                      <span className="icon-dribbble"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      target="__blank"
                      rel="noopener noreferrer"
                      href="https://shemul.me/"
                    >
                      <span className="icon-globe"></span>
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <h2>Zonayed Ahmed</h2>
                <p>Developed The Website, Interaction</p>
                <ul className="social">
                  <li>
                    <a
                      target="__blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/zonayedpca"
                    >
                      <span className="icon-twitter"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      target="__blank"
                      rel="noopener noreferrer"
                      href="https://www.linkedin.com/in/zonayedpca/"
                    >
                      <span className="icon-linked-in"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      target="__blank"
                      rel="noopener noreferrer"
                      href="https://github.com/zonayedpca"
                    >
                      <span className="icon-github"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      target="__blank"
                      rel="noopener noreferrer"
                      href="https://codepen.io/zonayedpca"
                    >
                      <span className="icon-codepen"></span>
                    </a>
                  </li>
                  <li>
                    <a
                      target="__blank"
                      rel="noopener noreferrer"
                      href="https://zonayed.me"
                    >
                      <span className="icon-globe"></span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </Sheet>
        <Subscription />
      </Container>
    </Layout>
  )
}
